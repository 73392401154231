<style lang="less">
	.entry {
		width: 1301px;
		min-width: 1301px;
		padding: 50px 56px 101px 38px;
		background-color: #fff;
		font-size: 22px;

		.personal_color {
			color: #CD6428;
		}

		.hind {
			position: absolute;
			right: 27px;
			bottom: 17px;
			color: #932224;
		}

		.operation {
			position: absolute;
			right: 28px;
			bottom: 56px;

			>div {
				width: 170px;
				height: 55px;
				justify-content: center;
				font-size: 16px;
				margin-top: 15px;
			}

			.operation_box {
				border: 1px solid #A8A8A8;
			}
		}

		>nav {
			align-items: flex-end;

			>.entry_nav {
				font-size: 32px;

				>div {
					padding-right: 68px;

					>.categoryColor {
						color: #932224;
						transition: all 0.4s linear 0s;
					}

					>.entry_solid {
						width: 20px;
						height: 4px;
						background-color: #fff;
						margin: 13px auto;
					}

					>.categoryBack {
						width: 48px;
						background-color: #932224;
						transition: all 0.4s linear 0s;
					}

					>.categoryOn {
						width: 0;
						background-color: #fff;
						transition: all 0.4s linear 0s;
					}
				}
			}

			>.entry_uploading {
				padding: 15px 18px;
				background-color: #932224;
				font-size: 16px;
				color: #fff;
				display: inline-block;

				>span {
					padding-right: 10px;
				}

				>.newentry_icon {
					display: inline-block;
					width: 16px;
					height: 16px;
					min-width: 16px;
					min-height: 16px;
					margin: 0 auto;
				}
			}
		}

		>aside {
			margin-top: 55px;

			>.production {
				background-color: #F5F5F5;
				padding: 43px 28px 63px 22px;
				width: 100%;
				margin-bottom: 26px;
				align-items: flex-start;
				position: relative;

				>.presentType {
					position: absolute;
					top: 36px;
					right: 31px;
					max-width: 200px;
				}

				>.production_icon {
					width: 392px;
					min-width: 392px;
					height: 392px;
					margin-right: 78px;
					position: relative;
					.production_medal{
						width: 70px;
						height: 40px;
						color: #5D4504;
						font-size: 16px;
						z-index: 99;
						position: absolute;
						right: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						top: 55px;
					}
				}

				>.production_conten {
					width: 462px;
					text-align: left;

					>p {
						padding: 29px 0;
						border-bottom: 1px solid #DFDFDF;

						>span {
							color: #666666;
						}
					}

					>p:first-child {
						padding-top: 0;
						padding-bottom: 29px;
					}
				}
			}
		}
	}

	.entryTc_box {
		>.entryTc_icon {
			width: 242px;
			height: 242px;
			margin: 35px auto;
		}

		>.entryTc_name {
			font-size: 22px;

			span {
				color: #666666;
			}
		}

		.operation_conten {
			width: 460px;
			margin: 0 auto;
			padding-top: 90px;

			>div {
				width: 212px;
				height: 66px;
				text-align: center;
				line-height: 66px;
			}

			.operation_box {
				border: 1px solid #A8A8A8;
			}
		}
	}
</style>
<template>
	<div class="entry">
		<nav class="flex_just_disp">
			<div></div>
			<!-- <div class="entry_nav flex_disp">
				<div v-for="(item,index) in $t('entry.category')" @click="categoryIndex=index">
					<p :class="[categoryIndex==index?'categoryColor':'']">{{item}}</p>
					<p class="entry_solid" :class="[categoryIndex==index?'categoryBack':'categoryOn']"></p>
				</div>
			</div> -->
			<div class="entry_uploading cursorS flex_disp" @click="gouploagding">
				<span>{{$t('entry.newentry')}}</span>
				<div class="newentry_icon icon_image">
					<img src="@/assets/shucna.png" />
				</div>
			</div>
		</nav>
		<div class="tishi" v-if="!entrList||entrList.length==0">
			<el-empty :image-size="200" :description="$t('newText.msg116')"></el-empty>
		</div>
		<aside class="aside">
			<div class="production flex_disp" v-for="(item,index) in entrList" :key="index">
				<div class="production_icon icon_image" >
					<img :src="item.en_img" style="object-fit: contain;">
					<div class="production_medal" v-if="item.cur_prize" :style="'background:url(https://cultural-creation.oss-cn-beijing.aliyuncs.com'+item.cur_prize.image+')'">
						<span>{{item.cur_prize.en_reward_name}}</span>
					</div>
				</div>
				<div class="production_conten ">
					<p v-show="item.en_check_state!=0">{{$t('entry.production')[0].title}} <span>{{item.number}}</span></p>
					<p>{{$t('entry.production')[1].title}} <span>{{item.en_name}}</span></p>
					<p>{{$t('entry.production')[2].title}} <span>{{item.en_track}}</span></p>
					<p>{{$t('entry.production')[3].title}} <span>{{item.update_time}}</span></p>
					<p>{{$t('entry.production')[4].title}} <span>{{item.author}}</span></p>
					<!-- <p v-for="(ie,i) in $t('entry.production')" :key="i">{{ie.title}}
						<span>{{item.update_time}}</span></p> -->
				</div>
				<p class="presentType" :class="item.en_check_state!=-1?'personal_color':''">
					<span v-if="item.en_check_state==1">
						{{$t('newWord.entryTitle1')}}
					</span>
					<span v-if="item.en_check_state==-1||item.en_check_state==-2||item.en_check_state==8">
						{{$t('newWord.entryTitle3')}}
					</span>
					<span v-if="item.en_check_state!=-1&&item.en_check_state!=-2&&item.en_check_state!=8&&item.en_check_state!=1">
						{{$t('newWord.entryTitle2')}}
					</span>
					<span
						v-if="item.en_check_state!=-1&&item.en_check_state!=-2">{{$t('entry.presentArr')[item.en_check_state]}}</span>
					<span v-if="item.en_check_state==-2">{{$t('entry.presentArrF')[0]}}</span>
					<span v-if="item.en_check_state==-1">{{$t('entry.presentArrF')[1]}}</span>
				</p>
				<div class="operation">
					<div class="operation_box cursorS flex_disp" v-if="item.en_check_state!=0&&item.en_check_state!=1||conten==0"
						@click="toUrl(item.author_num,item.id)">
						<span>{{$t('entry.examine')}}</span>
					</div>
					<div class="operation_box cursorS  flex_disp"
						@click.stop="$router.push({path:'ForUploading',query:{id:item.id}})"
						v-if="item.en_check_state==0||item.en_check_state==1&&conten!=0">
						<span>{{$t('entry.compile')}}</span>
					</div>
					<div class="operation_box cursorS flex_disp" @click.stop="entryItem=item;entryShow=true"
						v-if="item.en_check_state==0||item.en_check_state==1&&conten!=0">
						<span>{{$t('entry.delete')}}</span>
					</div>
					<div class="flex_disp cursorS" v-if="item.en_check_state==0">
						<Ebutton :text="$t('entry.submit')" @btnTap="submit(item.id)"></Ebutton>
					</div>
					<div class="flex_disp cursorS" v-if="item.en_check_state==5">
						<Ebutton :text="$t('entry.supple')"
							@btnTap="$router.push({path:'addAuthor',query:{id:item.id,number:item.author_num,types:1}})">
						</Ebutton>
					</div>
				</div>
				<p class="hind" v-show="item.en_check_state==0">{{$t('entry.hind')}}</p>
			</div>
		</aside>
		<entryTc :content="$t('personalCenter.tishi')" :title="$t('newText.msg134')" :show="entryShow">
			<template v-slot:content>
				<div class="entryTc_box">
					<div class="entryTc_icon icon_image">
						<img :src="entryItem.en_img">
					</div>
					<p class="entryTc_name">{{$t('entry.serial')}}{{entryItem.number}}</p>
					<div class="operation_conten flex_just_disp">
						<div class="operation_box cursorS flex_box" @click.stop="addAuthor()">
							<span>{{$t('entry.confirm')}}</span>
						</div>
						<div>
							<Ebutton :text="$t('entry.cancel')" @btnTap="entryShow=false"></Ebutton>
						</div>
					</div>
				</div>
			</template>
		</entryTc>
	</div>
</template>

<script>
	import entryTc from '@/components/dialog.vue';
	import Ebutton from '@/components/ele/elButton.vue';
	export default {
		components: {
			Ebutton,
			entryTc
		},
		props: {
			is_one_edit: [Boolean]
		},
		data() {
			return {
				categoryIndex: 0,
				entrList: [],
				entrId: '',
				entryShow: false,
				entryItem: '',
				conten:''
			}
		},
		mounted() {
			this.entriesList();
		},
		methods: {
			async submit(id) {
				try {
					let data = {
						id: id
					}
					let res = await this.$axios.post('entries/submitEntries', data);
					if (res.code == 1) {
						this.$message({
							offset: 120,
							message: this.$t("newText.msg46"),
							type: 'success',
						});
						this.entriesList();
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			toUrl(num, id) {
				this.$router.push({
					path: 'workInformation',
					query: {
						number: num,
						id: id,
						types: 2
					}
				})
			},
			async entriesList() {
				try {
					let data = {
						page: 0,
						limit: 100,
					}
					let res = await this.$axios.post('entries/list', data);
					this.accountInformationObj();
					if(res.data.data){
						res.data.data.forEach((item, index) => {
							item.en_img = item.en_img.split(',')[0]
						})
						this.entrList = res.data.data;
						console.log(this.entrList)
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			operSetId(id) {
				this.entryItem = item;
			},
			deleClick() {
				console.log('删除')
			},
			async addAuthor(id) {
				try {
					let data = {
						id: this.entryItem.id
					}
					let res = await this.$axios.post('entries/delEntries', data);
					if (res.code == 1) {
						this.entryShow = false;
						this.$message.success({message:res.msg,offset:120})
						this.entriesList();
					} else {
						this.$message.error({message:res.msg,offset:120})
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			async accountInformationObj() {
				let _this = this
				try {
					let res = await this.$axios.post('match/upLeftDays');
					if (res.code == 1) {
						this.conten = res.data.daynum;
						localStorage.setItem('dayNum',res.data.daynum);
						console.log(this.conten)
					}
				} catch (error) {
					console.log(error);
				}
			
			},
			gouploagding() {
				if(this.conten==0){
					this.$message({message:this.$t("newText.msg47"),offset:120})
					return
				}
				let EveryShow=localStorage.getItem('EveryShow')
				EveryShow=EveryShow=='true'?true:false;
				if (EveryShow) {
					this.$emit('everyDalogShow', true)
				} else {
					this.$router.push({
						name:'ForUploading'
					})
				}

			}
		}
	}
</script>
