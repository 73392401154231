<style scoped lang="less">
	.basicForm {
		/* margin: 0 auto; */
		display: flex;
		justify-content: center;
		margin-top: 44px;
	}

	.basic {
		background-color: white;
		width: 100%;
		padding: 30px 45px;
		text-align: center;
		margin-top: 20px;
	}

	.bt {
		width: 334px;
		height: 66px;
		line-height: 66px;
		background: #932224;
		font-size: 22px;
		color: #ffffff;
		margin: 45px auto;
	}

	.email {
		font-size: 33px;
		margin-top: 13px;

		img {
			width: 866px;
			height: 584px;
			object-fit: cover;
		}
	}

	.tittle {
		font-size: 28px;
		text-align: right;
		padding-bottom: 48px;
	}

	.name {
		background-color: white;
		width: 100%;
		padding: 30px 45px;
		text-align: center;
	}

	.account {
		background-color: #ffffff;
		min-height: 500px;
	}

	.account_box {
		width: 866px;
		margin: 0 auto;
	}

	.zhu {
		width: 866px;
		font-size: 18px;
		color: #666666;
		display: flex;
		justify-content: flex-start;
		padding-top: 30px;

		>div {
			padding: 0 28px;
			border-right: 1px solid #979797;
		}

		>div:nth-child(3) {
			border: none;
		}

		>div:nth-child(1) {
			padding-left: 0;
		}

		span {
			color: #000000;
		}
	}

	.tishi {
		font-size: 56px;
		color: #ededed;
	}
</style>
<template>
	<div class="account">
		<div class="tishi" v-if="tableData.length==0">
			<el-empty :image-size="200"  :description="$t('newText.msg26')"></el-empty>
		</div>
		<div class="name" v-for="(item,index) in tableData" :key="index">
			<div class="tittle">{{$t('personalCenter.serialNumber')}}：{{item.en_certificate_code }}</div>
			<div class="account_box">
				<div class="email">
					<img style="object-fit: contain;" :src="item.en_certificate" />
				</div>
				<div class="zhu">
					<div>
						<span>{{$t('personalCenter.contestName')}}：</span>{{item.en_match_name}}
					</div>
					<div>
						<span>{{$t('personalCenter.track')}}：</span>{{item.en_track}}
					</div>
					<div>
						<span>{{$t('personalCenter.titleEntry')}}：</span>{{item.en_name}}
					</div>
				</div>
				<div class="zhu">
					<div>
						<span>{{$t('personalCenter.winningTime')}}：</span>{{item.award_time }}
					</div>
					<div>
						<span>{{$t('personalCenter.awards')}}：</span>{{item.en_prize}}
					</div>
					<div>
						<span>{{$t('personalCenter.author')}}：</span>{{item.author }}
					</div>
				</div>
				<el-link target="_blank" :href="item.en_certificate" :underline="false">
					<div class="bt" @click.stop="downloadChange(item.en_certificate)">{{$t('personalCenter.download')}}</div>
				</el-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: []
			}
		},
		mounted() {
			this.downloadList()
		},
		methods: {
			downloadChange(url) {
				this.$util.dowloadfr(url,'证书')
			},
			async downloadList() {
				let _this = this
				console.log(_this.tableData)
				try {
					let params = {
						page: 1,
					}
					let res = await _this.$axios.post('entries/certList', params);
					if (res.code == 1) {
						let data = res.data
						_this.tableData = data
						_this.tableData.forEach(item=>{
							console.log(item.en_certificate.indexOf('https'))
							if(item.en_certificate.indexOf('https')!=0){
								item.en_certificate='https://v2admin.rmwc.com.cn'+item.en_certificate
							}
						})
						// _this.tableData.push(data[0])
					}
				} catch (error) {
					console.log(error);
				}

			},
		}
	}
</script>
