<style lang="less" scoped>
	.content {
		padding: 51px;
		background-color: white;

		.title {
			display: flex;
			padding: 0 134px;

			div {
				font-size: 26px;
				width: 33%;
				cursor: pointer;
			}

			.active {
				// transition: 1s;
				padding-top: 10px;
				width: 76px;
				border-bottom: 2px solid #932224;
				margin: 0 auto;
			}
		}

		.recommendedRecord {
			padding-top: 40px;

			/deep/ .el-table {
				font-size: 22px;
				font-weight: 400;
				color: #000000;

				.el-table__row {
					height: 68px;
					color: #666666;
					font-size: 18px;
				}

				.cell {}

				.el-table__cell {
					padding: 20px 0;
					font-weight: 400;
					color: #000000;
				}
			}

			.zhu {
				font-size: 22px;
				color: #932224;
				padding-top: 47px;
				text-align: left;
			}

			.invite {
				display: flex;
				padding: 0 80px;

				.invite_head {
					display: flex;
					width: 255px;
					height: 115px;
					border-radius: 1px;
					border: 1px solid #979797;
					align-items: center;
					margin-right: 14px;
					padding: 20px;

					.headImg {
						width: 73px;
						height: 73px;
					}

					.head_text {
						width: 125px;
						margin-left: 20px;

						div {
							font-size: 32px;
							color: #932224;
						}

						p {
							font-size: 14px;
						}
					}
				}
			}

			.invite_box {
				width: 1092px;
				height: 665px;
				background: #FBFBFB;
				border-radius: 1px;
				margin: 96px 80px 271px 80px;
				padding: 48px 35px;
				text-align: left;

				.invite_title {
					font-size: 32px;
				}

				.invite_text {
					font-size: 24px;
					margin-top: 53px;

					span {
						color: #932224;
					}
				}

				.invite_explain {
					margin-top: 22px;
					color: #666666;
				}
			}

			.invite_bth {
				display: flex;
				align-items: center;
				margin-top: 88px;
				font-size: 24px;

				span {
					color: #932224;
				}

				.btn {
					height: 54px;
					padding-left: 30px;
				}
			}
		}
	}
</style>
<template>
	<div class="content">
		<div class="title">
			<div v-for="(item,index) in title" :key="index" :style="{'color':headTxtIndex==index?'#932224':'#666666'}"
				@click="textClick(index)">
				{{item.value}}
				<div :class="{active:headTxtIndex==index}"></div>
			</div>
		</div>
		<div class="recommendedRecord" v-show="headTxtIndex==0">
			<div class="invite">
				<div class="invite_head">
					<div class="icon_image headImg">
						<img src="@/assets/personal1.png" />
					</div>
					<div class="head_text">
						<div>01</div>
						<p>{{$t('personalCenter.inviteHead01')}}</p>
					</div>
				</div>
				<div class="invite_head">
					<div class="icon_image headImg">
						<img src="@/assets/personal2.png" />
					</div>
					<div class="head_text">
						<div>02</div>
						<p>{{$t('personalCenter.inviteHead02')}}</p>
					</div>
				</div>
				<div class="invite_head">
					<div class="icon_image headImg">
						<img src="@/assets/personal3.png" />
					</div>
					<div class="head_text">
						<div>03</div>
						<p>{{$t('personalCenter.inviteHead03')}}</p>
					</div>
				</div>
				<div class="invite_head">
					<div class="icon_image headImg">
						<img src="@/assets/personal4.png" />
					</div>
					<div class="head_text">
						<div>04</div>
						<p>{{$t('personalCenter.inviteHead04')}}</p>
					</div>
				</div>
			</div>
			<div class="invite_box">
				<div class="invite_title">{{$t('personalCenter.inviteExplain')}}:</div>
				<div class="invite_text">
					{{$t('personalCenter.inviteTitle1')}}<span>{{$t('personalCenter.jiang1')}}</span>+{{$t('personalCenter.jiang')}}
				</div>
				<div class="invite_explain">
					{{$t('personalCenter.inviteExplain1')}}
				</div>
				<div class="invite_text">
					{{$t('personalCenter.inviteTitle2')}}<span>{{$t('personalCenter.jiang2')}}</span>+{{$t('personalCenter.jiang')}}
				</div>
				<div class="invite_explain">
					{{$t('personalCenter.inviteExplain2')}}
				</div>
				<div class="invite_bth">
					<!-- 打包 -->
					{{$t('personalCenter.inviteUrl')}}:<span>http://v2.rmwc.com.cn/register?id={{$store.state.user_id}}</span>
					<div class="btn">
						<elbutton @btnTap="copy" :text="$t('personalCenter.copyUrl')"></elbutton>
					</div>
				</div>
			</div>

		</div>
		<div class="recommendedRecord" v-show="headTxtIndex==1">
			<div>
				<el-table :data="tableData" stripe style="width: 100%" border :empty-text="$t('newText.msg89')">
					<el-table-column prop="zhuti_name" :label="$t('personalCenter.nameRecommender')" align="center">
					</el-table-column>
					<el-table-column prop="create_time" :label="$t('personalCenter.accountRegistrationTime')"
						align="center">
					</el-table-column>
					<el-table-column prop="address" :label="$t('personalCenter.whetherUpload')" align="center">
						<template v-slot="scope">
							{{scope.row.isUpEntrise==1?$t('newText.msg31'):$t('newText.msg32')}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="zhu">{{$t('personalCenter.recommendedRecordZhu')}}</div>
		</div>
		<div class="recommendedRecord" v-show="headTxtIndex==2">
			<el-table :data="parentsList" stripe style="width: 100%" border :empty-text="$t('newText.msg89')">
				<el-table-column prop="en_certificate_name" :label="$t('personalCenter.prizesName')" align="center">
				</el-table-column>
				<el-table-column prop="num" :label="$t('personalCenter.winningNumber')" align="center">
				</el-table-column>
				<el-table-column prop="award_time" :label="$t('personalCenter.winningTime')" align="center">
				</el-table-column>
				<el-table-column prop="price" :label="$t('personalCenter.getAmount')" align="center">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import elbutton from '@/components/ele/elButton.vue'
	export default {
		components: {
			elbutton
		},
		data() {
			return {
				everyShow: false,
				num: 1,
				tableData: [],
				parentsList: [],
				title: [{
						value: this.$t('personalCenter.invitedRecommend'),
						show: true,
					},
					{
						value: this.$t('personalCenter.recommendedRecord'),
						show: false,
					},
					{
						value: this.$t('personalCenter.prizeWinning'),
						show: false,
					},
				],
				headTxtIndex: 0
			}
		},
		mounted() {
			this.recommendList();
			this.parentList();
			
			// this.everyShow=localStorage.getItem('EveryShow')
			this.accountInformationObjs()
			console.log(this.everyShow)
			this.everyShow=localStorage.getItem('EveryShow')
			// this.USERTYPE=localStorage.getItem('USERTYPE')
		},
		methods: {
			//卡布达写的
			copy() {
				console.log(this.everyShow)
				if (this.everyShow=='true'||this.everyShow==true) {
					this.$message.error({
						message: this.$t('personalCenter.tishi1'),
						offset: 120
					})
				}
				// if (this.USERTYPE=='2'||this.USERTYPE==2) {
				// 	this.$message.error({
				// 		message: this.$t('personalCenter.tishi2'),
				// 		offset: 120
				// 	})
				// }
				if (this.everyShow=='false'||this.everyShow==false) {
					//打包中文 v2 英文: v2e
					this.$copyText('http://v2.rmwc.com.cn/register?index=1&id=' + this.$store.state.user_id).then(function(e) {
							// this.$message({
							// 	message: this.$t('newText.msg28'),
							// 	offset: 120,
							// 	type: 'success'
							// });
						})

				}
			},
			async accountInformationObjs() {
				let _this = this
				try {
					let params = {}
					let res = await _this.$axios.post('user/index', params);
					if (res.code == 1) {
						let data = res.data;

						// _this.everyShow = data.user.is_one_edit == 0&&data.user.u_user_type!=2 ? true : false
						_this.everyShow = data.user.is_one_edit != 1 ? true : false
					}
				} catch (error) {
					console.log(error);
				}

			},
			async recommendList(val) {
				let _this = this
				try {
					let params = {
						page: 1,

					}
					let res = await this.$axios.post('user/children', params);
					if (res.code == 1) {
						// let data = res.data
						this.tableData = res.data
					}
				} catch (error) {
					console.log(error);
				}

			},
			async parentList(val) {
				let _this = this
				try {
					let params = {}
					let res = await this.$axios.post('user/user_parent_reward', params);
					if (res.code == 1) {
						this.parentsList = res.data.user_award
						this.parentsList.forEach((v, i) => {
							// console.log(v)
							this.$set(v, 'num', 1)
						})

						// if (res.data.user_award) {
						// 	this.$set(res.data.user_award, 'name', this.$t('newText.msg29'))
						// 	this.parentsList.push(res.data.user_award)
						// }
						// if (res.data.user_award) {
						// 	this.$set(res.data.user_award, 'name', this.$t('newText.msg30'))
						// 	this.parentsList.push(res.data.user_award)
						// }

					}
				} catch (error) {
					console.log(error);
				}

			},

			textClick(txt) {
				this.headTxtIndex = txt
				this.title.forEach(element => {
					element.show = element.value == txt ? true : false
				})
			},
		},

	}
</script>
