<style scoped lang="less">
	.basicInformation1 {
		font-size: 20px;
	}

	.basicForm {
		/* margin: 0 auto; */
		// display: flex;
		// justify-content: center;
		margin-top: 44px;
	}

	.basic {
		background-color: white;
		width: 100%;
		padding: 30px 45px;
		text-align: center;
		margin-top: 20px;

		/deep/.el-form-item__label:before {
			content: '' !important;
			color: #F56C6C;
			margin-right: 0 !important;
		}

		/deep/.el-radio__inner {
			width: 20px;
			height: 20px;
		}

		/deep/.el-form-item__label:after {
			content: '*' !important;
			color: #F56C6C !important;
			margin-right: 0.25rem;
		}
	}

	.bt {
		max-width: 255px;
		height: 44px;
		line-height: 44px;
		background: #932224;
		font-size: 16px;
		color: #ffffff;
		margin: 45px auto;
		cursor: pointer;
	}

	.email {
		font-size: 33px;
		margin-top: 13px;
	}

	.tittle {
		font-size: 28px;
		text-align: left;

		>span {
			display: inline-block;
			width: 8px;
			height: 24px;
			background: #932224;
			border-radius: 5px;
			margin-right: 6px;
		}
	}

	.name {
		background-color: white;
		width: 100%;
		padding: 30px 45px;
		text-align: center;
	}

	.account {}

	.zhu {
		margin-top: 45px;
		font-size: 20px;
		color: #666666;
	}

	/deep/ .el-input__inner {
		height: 58px;
		font-size: 22px;
	}

	/deep/ .el-form-item__label {
		font-size: 22px;
		line-height: 58px;
	}

	/deep/ .el-radio__label {
		font-size: 22px;
	}

	/deep/ .el-radio__input {
		margin-bottom: px(5);

		&.is-checked {
			.el-radio__inner {
				background-color: #932224;
				border-color: #932224;
			}
		}

		.el-radio__inner {
			&:hover {
				border-color: #932224;
			}
		}
	}

	/deep/ .el-radio__input.is-checked+.el-radio__label {
		color: #606266;
	}

	/deep/ .el-form-item__content {
		text-align: initial;
		line-height: 58px;
	}

	/deep/ .el-cascader {
		width: 460px;
	}

	.width460 {
		width: 460px;
	}

	.width230 {
		width: 230px;
		float: left;
		padding-right: 5px
	}

	.up_img {
		width: 102px;
		height: 102px;
		border: 1px solid #ccc;
		text-align: center;

		p {
			line-height: 0;
			color: #9D9D9D;
			font-size: 20px;
			word-break: break-all;
		}

		img {
			margin-top: 15px;
			width: 38px;
			height: 38px;
		}
	}

	.up_photo {
		width: 102px;
		height: 102px;
		border: 1px solid #979797;
	}

	.btn {
		width: 334px;
		height: 66px;
		margin: 67px auto;
	}

	.bottom_radio {
		// width: 100%;
		min-width: 70%;
		max-width: 40%;
		margin-top: 18px;
		word-wrap: break-word;

	}

	.bottom_radios {
		min-width: 40%;
		// margin-top: 37px;
		word-wrap: break-word;
	}

	.pass_login {
		width: 70%;
		margin: 0 auto;
		margin-top: 20px;
	}
</style>
<template>
	<div class="account">
		<div class="name">
			<div class="tittle flex_disp"><span></span> {{$t('personalCenter.accountNo')}}</div>
			<div class="email">{{$t('personalCenter.registerEmail')}}<span>{{ruleForm.u_email}}</span></div>
			<div class="bt" @click="passWordShow=true">{{$t('personalCenter.changePassword')}}</div>
			<div class="zhu">{{$t('personalCenter.emailComments')}}</div>
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="16.27604vw" class="demo-ruleForm">
			<div class="basic">
				<div class="tittle flex_disp"><span></span> {{$t('personalCenter.basicInformation')}}<a
						class="basicInformation1">{{$t('personalCenter.basicInformation1')}}</a></div>
				<div class="basicForm">
					<el-form-item :label="$t('personalCenter.selectThe')" prop="type" v-if="u_user_type==1">
						<el-radio-group v-model="ruleForm.type">
							<el-radio :label="1" :disabled="ruleForm.is_one_edit!=0">{{$t('personalCenter.personage')}}
							</el-radio>
							<el-radio :label="2" :disabled="ruleForm.is_one_edit!=0">{{$t('personalCenter.company')}}
							</el-radio>
							<el-radio :label="3" :disabled="ruleForm.is_one_edit!=0">
								{{$t('personalCenter.organization')}}
							</el-radio>
							<el-radio :label="4" :disabled="ruleForm.is_one_edit!=0">{{$t('personalCenter.academy')}}
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('personalCenter.headPortrait')" prop="avatar">
						<div>
							<div class="up_img" v-if="ruleForm.avatar==''">
								<img src="@/assets/phone.png" />
								<p>{{$t('personalCenter.photo')}}</p>
							</div>
							<div class="icon_image up_photo" v-else>
								<img :src="ruleForm.avatar" />
							</div>
							<div>
								<uploading @success="success" name="TX" v-if="ruleForm.is_one_edit==0"
									style="width: 6.375rem;"></uploading>
							</div>
						</div>
					</el-form-item>
					<el-form-item :label="$t('personalCenter.name')" prop="xing"
						v-if="ruleForm.type==1||u_user_type==2">
						<div class="width230">
							<el-input v-model="ruleForm.xing" :disabled="ruleForm.is_one_edit!=0"
								:placeholder="$t('personalCenter.surname')">
							</el-input>
						</div>
						<div class="width230">
							<el-input v-model="ruleForm.ming" :disabled="ruleForm.is_one_edit!=0"
								:placeholder="$t('personalCenter.givenName')">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('personalCenter.nationality')" prop="co_name"
						v-if="ruleForm.type==1||u_user_type==2">
						<el-select v-model="ruleForm.co_name" placeholder class="width460"
							:disabled="ruleForm.is_one_edit!=0" :placeholder="$t('addAuthor.hintnationality')">
							<el-option v-for="(item,index) in countryList" :key="index" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="$t('personalCenter.yearBirth')" prop="date_of_birth"
						v-if="ruleForm.type==1&&u_user_type==1">
						<el-date-picker value-format=" yyyy" :editable="false"
							v-model="ruleForm.date_of_birth" type="year" class="width460"
							:placeholder="$t('personalCenter.secletYearBirth')" :disabled="ruleForm.is_one_edit!=0"
							:picker-options="pickerBeginDateBefore">
						</el-date-picker>
					</el-form-item>

					<div v-if="u_user_type==1">
						<el-form-item
							:label="ruleForm.type==2? $t('personalCenter.type2_1'):ruleForm.type==3? $t('personalCenter.type3_1'):ruleForm.type==4? $t('personalCenter.type4_1'):''"
							prop="company" v-if="ruleForm.type!=1">
							<div class="width460">
								<el-input v-model="ruleForm.company" :disabled="ruleForm.is_one_edit!=0"
									:placeholder="ruleForm.type==2? $t('personalCenter.fillAffiliated1'):ruleForm.type==3? $t('personalCenter.fillAffiliated2'):ruleForm.type==4? $t('personalCenter.fillAffiliated3'):''">
								</el-input>
							</div>
						</el-form-item>
						<el-form-item
							:label="ruleForm.type==2? $t('personalCenter.type2_2'):ruleForm.type==3? $t('personalCenter.type3_2'):ruleForm.type==4? $t('personalCenter.type4_2'):''"
							prop="co_name" v-if="ruleForm.type!=1">
							<el-select v-model="ruleForm.co_name" :placeholder="$t('personalCenter.nationalitys')"
								class="width460" :disabled="ruleForm.is_one_edit!=0">
								<el-option v-for="(item,index) in countryList" :key="index" :label="item.name"
									:value="item.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<!-- <el-select v-model="ruleForm.co_name" :placeholder="$t('personalCenter.nationalitys')"
						class="width460">
						<el-option v-for="(item,index) in countryList" :key="index" :label="item.name"
							:value="item.name"></el-option>
					</el-select>
					00000 -->
					<div v-if="ruleForm.co_name=='中国 (China)'&&u_user_type==2">
						<el-form-item :label="$t('newText.msg102')" prop="identity"
							v-if="ruleForm.co_name=='中国 (China)'">
							<div class="width460">
								<el-input v-model="ruleForm.identity" :placeholder="$t('newText.msg103')"
									:maxlength="18" :disabled="ruleForm.is_one_edit!=0">
								</el-input>
							</div>
						</el-form-item>
					</div>

				</div>
			</div>
			<div class="basic">
				<div class="tittle flex_disp"><span></span>{{$t('personalCenter.contactInformation')}}</div>
				<div class="basicForm">
					<div v-if="ruleForm.co_name=='中国 (China)'">
						<el-form-item
							:label="ruleForm.type==1?$t('personalCenter.city'):ruleForm.type==2?$t('personalCenter.type2_3'):ruleForm.type==3?$t('personalCenter.type3_3'):ruleForm.type==4?$t('personalCenter.type4_3'):$t('personalCenter.city')"
							prop="city" v-if="ruleForm.co_name=='中国 (China)'">
							<el-cascader size="large" :options="options" v-model="ruleForm.city" @change="handleChange"
								:placeholder="$t('newText.msg117')" :props="{value:'label'}">
							</el-cascader>
							<!-- ka -->
						</el-form-item>
					</div>
					<el-form-item
						:label="ruleForm.type==1?$t('personalCenter.detailedAddres'):ruleForm.type==2?$t('personalCenter.type2_4'):ruleForm.type==3?$t('personalCenter.type3_4'):ruleForm.type==4?$t('personalCenter.type4_4'):$t('personalCenter.detailedAddres')"
						prop="addr">
						<div class="width460">
							<el-input v-model="ruleForm.addr" :placeholder="$t('personalCenter.fillDetailedAddres')">
							</el-input>
						</div>
					</el-form-item>
					<div v-if="u_user_type==1">
						<el-form-item :label="$t('personalCenter.names')" prop="xing" v-if="ruleForm.type!=1">
							<div class="width230">
								<el-input v-model="ruleForm.xing" :placeholder="$t('personalCenter.surname')">
								</el-input>
							</div>
							<div class="width230">
								<el-input v-model="ruleForm.ming" :placeholder="$t('personalCenter.givenName')">
								</el-input>
							</div>
						</el-form-item>
					</div>

					<el-form-item :label="$t('personalCenter.phone')" prop="mobile">
						<div class="width460">
							<el-input  v-model="ruleForm.mobile" :maxlength="20"
								:placeholder="$t('personalCenter.fillPhone')">
							</el-input>
						</div>
					</el-form-item>
				</div>
			</div>
			<div v-if="u_user_type==1">
				<div class="basic" v-if="ruleForm.is_one_edit==0||ruleForm.type==1">
					<div class="tittle flex_disp"><span></span>{{$t('personalCenter.otherInformation')}}</div>
					<div class="basicForm">
						<el-form-item :label="$t('personalCenter.affiliated')" prop="company" v-if="ruleForm.type==1">
							<div class="width460">
								<el-input v-model="ruleForm.company" :placeholder="$t('personalCenter.fillAffiliated')">
								</el-input>
							</div>
						</el-form-item>
						<el-form-item :label="$t('personalCenter.position')" prop="job" v-if="ruleForm.type==1">
							<div class="width460">
								<el-input v-model="ruleForm.job" :placeholder="$t('personalCenter.fillPosition')">
								</el-input>
							</div>
						</el-form-item>
						<el-form-item :label="$t('personalCenter.freelanceNot')" prop="is_freedom"
							v-if="ruleForm.type==1&&u_user_type!=2">
							<el-radio-group v-model="ruleForm.is_freedom" text-color="#932224" fill="#932224">
								<el-radio :label="1">{{$t('personalCenter.yes')}}</el-radio>
								<el-radio :label="2">{{$t('personalCenter.no')}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item :label="$t('personalCenter.approach')" prop="know_path"
							v-if="ruleForm.is_one_edit==0">
							<el-radio-group v-model="ruleForm.know_path" text-color="#932224" fill="#932224">
								<el-radio :label="1" class="bottom_radios">
									{{$t('personalCenter.approach1')}}
								</el-radio>
								<el-radio :label="2" class="bottom_radio">
									{{$t('personalCenter.approach2')}}
								</el-radio>
								<el-radio :label="3" class="bottom_radio">{{$t('personalCenter.approach3')}}
								</el-radio>
								<el-radio :label="4" class="bottom_radio">{{$t('personalCenter.approach4')}}
								</el-radio>
								<el-radio :label="5" class="bottom_radio">{{$t('personalCenter.approach5')}}
								</el-radio>
								<el-radio :label="6" class="bottom_radio">{{$t('personalCenter.approach6')}}
								</el-radio>
								<el-radio :label="7" class="bottom_radio">{{$t('personalCenter.approach7')}}
								</el-radio>
								<el-radio :label="8" class="bottom_radio">{{$t('personalCenter.approach8')}}</el-radio>
							</el-radio-group>
						</el-form-item>
					</div>
				</div>
			</div>

			<div class="basic" v-if="u_user_type==2">
				<div class="tittle flex_disp"><span></span>{{$t('personalCenter.otherInformation')}}</div>
				<div class="basicForm">
					<el-form-item :label="$t('personalCenter.affiliated')" prop="company">
						<div class="width460">
							<el-input v-model="ruleForm.company" :placeholder="$t('personalCenter.fillAffiliated')">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('personalCenter.position')" prop="job">
						<div class="width460">
							<el-input v-model="ruleForm.job" :placeholder="$t('personalCenter.fillPosition')">
							</el-input>
						</div>
					</el-form-item>
					<!-- <el-form-item :label="$t('personalCenter.freelanceNot')" prop="is_freedom"
						v-if="ruleForm.is_one_edit==0&&u_user_type==1">
						<el-radio-group v-model="ruleForm.is_freedom" text-color="#932224" fill="#932224">
							<el-radio :label="1">{{$t('personalCenter.yes')}}</el-radio>
							<el-radio :label="2">{{$t('personalCenter.no')}}</el-radio>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item :label="$t('personalCenter.approach')" prop="know_path"
						v-if="ruleForm.is_one_edit==0">
						<el-radio-group v-model="ruleForm.know_path" text-color="#932224" fill="#932224">
							<el-radio :label="1" class="bottom_radios">
								{{$t('personalCenter.approach1')}}
							</el-radio>
							<el-radio :label="2" class="bottom_radio">
								{{$t('personalCenter.approach2')}}
							</el-radio>
							<el-radio :label="3" class="bottom_radio">{{$t('personalCenter.approach3')}}
							</el-radio>
							<el-radio :label="4" class="bottom_radio">{{$t('personalCenter.approach4')}}
							</el-radio>
							<el-radio :label="5" class="bottom_radio">{{$t('personalCenter.approach5')}}
							</el-radio>
							<el-radio :label="6" class="bottom_radio">{{$t('personalCenter.approach6')}}
							</el-radio>
							<el-radio :label="7" class="bottom_radio">{{$t('personalCenter.approach7')}}
							</el-radio>
							<el-radio :label="8" class="bottom_radio">{{$t('personalCenter.approach8')}}</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
			</div>
		</el-form>
		<div class="btn">
			<elbutton @btnTap="btnTap"
				:text="ruleForm.is_one_edit!=0?$t('personalCenter.showBtn2'):$t('personalCenter.showBtn1')"></elbutton>
		</div>
		<dialogs :show="passWordShow" @closeBindWarnStandard="passWordShow=false" :title="$t('personalCenter.change')"
			:width="'50.78125vw'">
			<template v-slot:content>
				<div class="pass_login">
					<el-form ref="form" :model="form" :rules="wordrules" label-width="9.76563vw">
						<el-form-item :label="$t('personalCenter.oldpassword')" prop="oldpassword">
							<el-input v-model="form.oldpassword" :placeholder="$t('personalCenter.oldpassword1')">
							</el-input>
						</el-form-item>
						<el-form-item :label="$t('personalCenter.newpassword')" prop="newpassword">
							<el-input v-model="form.newpassword" :placeholder="$t('personalCenter.newpassword1')">
							</el-input>
							<p style="color: #932224;">{{$t('logIn.setpassword.infuse')}}</p>
						</el-form-item>
						<el-form-item :label="$t('personalCenter.confirmpassword')" prop="newpassword1">
							<el-input v-model="form.newpassword1" :placeholder="$t('personalCenter.confirmpassword1')">
							</el-input>
						</el-form-item>
					</el-form>
				</div>
			</template>
			<template v-slot:foot>
				<div class="btn">
					<elbutton @btnTap="confirmpassword" :text="$t('personalCenter.showBtn')"></elbutton>
				</div>
			</template>
		</dialogs>
	</div>
</template>

<script>
	import {
		regionData,
	} from 'element-china-area-data'
	import formInput from '@/components/ele/formInput.vue'
	import uploading from '@/components/ele/uploading.vue'
	import elbutton from '@/components/ele/elButton.vue'
	import dialogs from '@/components/dialog.vue'
	import {
		isPasswrd
	} from '@/components/ele/formRules.js'

	export default {
		components: {
			formInput,
			uploading,
			elbutton,
			dialogs
		},
		data() {
			let validatePassWord = (rule, value, callback) => {
				if (!isPasswrd(value)) {
					callback(new Error(this.$t('newText.msg33')))
				} else {
					callback()
				}
			}
			var validatePass = (rule, value, callback) => {
				console.log(this.form)
				if (value === '') {
					callback(new Error(this.$t('newText.msg34')));
				} else if (value !== this.form.newpassword) {
					callback(new Error(this.$t('newText.msg35')));
				} else {
					callback();
				}
			};
			return {
				u_user_type: 0,
				passWordShow: false,
				pickerBeginDateBefore: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					}
				},
				countryList: this.$util.countryList,
				everyShow: false,
				value: "",
				options: regionData,
				form: {
					oldpassword: "",
					newpassword: "",
					newpassword1: "",
				},
				addshow: true,
				ruleForm: {
					id: '',
					type: 1,
					avatar: "",
					xing: "",
					ming: "",
					date_of_birth: '',
					city: [],
					addr: "",
					mobile: "",
					company: "",
					job: "",
					co_name: "",
					is_freedom: '',
					know_path: '',
					is_one_edit: 0,
					u_email: "",
					identity: ''
				},
				rules: {
					type: [{
						required: true,
						message: this.$t('personalCenter.reminder1'),
						trigger: 'blur'
					}],
					avatar: [{
						required: true,
						message: this.$t('personalCenter.reminder2'),
						trigger: 'blur'
					}],
					date_of_birth: [{
						required: true,
						message: this.$t('personalCenter.reminder3'),
						trigger: 'change'
					}],
					co_name: [{
						required: true,
						message: this.$t('personalCenter.reminder4'),
						trigger: 'change'
					}],
					xing: [{
						required: true,
						message: this.$t('personalCenter.reminder5'),
						trigger: 'blur'
					}],
					city: [{
						required: true,
						message: this.$t('personalCenter.reminder6'),
						trigger: 'change'
					}],
					addr: [{
						required: true,
						message: this.$t('personalCenter.reminder7'),
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: this.$t('personalCenter.reminder9'),
						trigger: 'blur'
					}],
					job: [{
						required: true,
						message: this.$t('personalCenter.reminder10'),
						trigger: 'blur'
					}],
					is_freedom: [{
						required: true,
						message: this.$t('personalCenter.reminder11'),
						trigger: 'blur'
					}],
					know_path: [{
						required: true,
						message: this.$t('personalCenter.reminder12'),
						trigger: 'blur'
					}],
					company: [{
						required: true,
						message: this.$t('personalCenter.reminder13'),
						trigger: 'blur'
					}],
					identity: [{
						required: true,
						message: this.$t('newText.msg103'),
						trigger: 'blur'
					}]
				},
				wordrules: {
					oldpassword: [{
						required: true,
						message: this.$t('newText.msg36'),
						trigger: 'blur'
					}],
					newpassword: [{
						required: true,
						message: this.$t('newText.msg37'),
						trigger: 'blur'
					}, {
						validator: validatePassWord,
						trigger: 'blur'
					}, {
						min: 8,
						message: this.$t('newText.msg38'),
						trigger: 'blur'
					}],
					newpassword1: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}]
				}
			}
		},
		mounted() {
			this.getAccountInformationObj()
			this.options.forEach((item, index) => {
				if (item.label == "海外(overseas)") {
					this.addshow = false
				}
			})
			if (this.addshow) {
				this.options.push({
					children: [{
						children: [{
							label: "海外(overseas)",
							value: "22222",
						}],
						label: "海外(overseas)",
						value: "22222",
					}],
					label: "海外(overseas)",
					value: "11111",
				})
			}
			this.accountInformationObj()
			// this.everyShow=localStorage.getItem('EveryShow')
		},
		methods: {
			async confirmpassword(value) {
				console.log(value)
				this.$refs.form.validate(async valid => {
					if (valid) {
						try {
							console.log("id" + this.ruleForm.id)
							let _this = this
							let params = {
								type: 2,
								newpassword: this.form.newpassword1,
								oldpassword: this.form.oldpassword,
								user_id: this.ruleForm.id
							}
							let res = await this.$axios.post('user/resetpwd', params);
							if (res.code == 1) {
								this.$message({
									offset: 100,
									message: this.$t('newText.msg39'),
									type: 'success'
								});
								this.$router.push("/login")
								localStorage.clear();
								sessionStorage.clear();
								this.passWordShow = false
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			async getAccountInformationObj() {
				let _this = this
				try {
					let params = {}
					let res = await this.$axios.post('user/index', params);
					if (res.code == 1) {
						let data = res.data;
						let everyShow = data.user.is_one_edit == 0 ? true : false;
						_this.u_user_type = data.user.u_user_type
						localStorage.setItem('EveryShow', everyShow);
					}
				} catch (error) {}
			},
			async btnTap(val) {
				let _this = this
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						console.log(_this.options)
						try {
							//卡布达写的
							let arr=[]
							let newarr=[]
							let arr2=[]
							let newarr2=[]
								let arr3=[]
							_this.options.forEach((item)=>{//遍历省
								console.log( _this.ruleForm.city[0])
								console.log(item)
								if(item.label==_this.ruleForm.city[0]){
									arr.push(item.value)
									newarr=item.children
								}
								// console.log(newarr)
							})
							newarr.forEach((item2)=>{//遍历市
								if(item2.label==_this.ruleForm.city[1]){
									arr2.push(item2.value)
									newarr2=item2.children
								}
								// console.log(newarr2)
								})
								newarr2.forEach((item3)=>{//遍历区
									if(item3.label==_this.ruleForm.city[2]){
										arr3.push(item3.value)
										
									}
									})
							console.log(arr)
							console.log(arr2)
							console.log(arr3)
							let obj = _this.countryList.filter(item => item.name == _this.ruleForm.co_name)
						
							
							// console.log(obj)
							let params = {
								type: parseInt(_this.ruleForm.type),
								avatar: _this.ruleForm.avatar,
								surname: _this.ruleForm.xing,
								name: _this.ruleForm.ming,
								date_of_birth: _this.ruleForm.date_of_birth,
								p_name: _this.ruleForm.city[0],
								c_name: _this.ruleForm.city[1],
								a_name: _this.ruleForm.city[2],
								know_path: parseInt(_this.ruleForm.know_path),
								addr: _this.ruleForm.addr,
								mobile: _this.ruleForm.mobile,
								company: _this.ruleForm.company,
								job: _this.ruleForm.job,
								co_id: obj[0].areaCode,
								co_name: obj[0].name,
								is_freedom: parseInt(_this.ruleForm.is_freedom),
								identity: _this.ruleForm.identity,
								p_id: arr[0]||'',
								c_id: arr2[0]||'',
								a_id: arr3[0]||''
							}
							let res = await this.$axios.post('user/profile', params);
							if (res.code == 1) {
								this.$message({
									offset: 100,
									message: this.$t('newText.msg40'),
									type: 'success',
								});
								this.accountInformationObj();
								this.getAccountInformationObj();
								// window.location.reload();
								if (_this.ruleForm.is_one_edit == 0) {
									console.log(_this.ruleForm.is_one_edit);
									localStorage.setItem('EveryShow', true);
									// _this.$router.push({
									// 	name: 'ForUploading',
									// })
								}
							}
							//卡布达写的
							// window.location.reload()
						} catch (error) {
							console.log(error);
						}
					} else {
						console.log('error submit!!')
						return false
					}
				})
				
			},
			async accountInformationObj(val) {
				let _this = this
				try {
					let params = {}
					let res = await this.$axios.post('user/index', params);
					if (res.code == 1) {
						let data = res.data;
						if (data.user.is_one_edit != 0) {
							this.ruleForm = {
								id: data.user.user_id,
								type: data.user.u_type,
								avatar: data.user.u_head_img,
								date_of_birth: data.user.u_date_of_birth,
								addr: data.user.a_addr,
								mobile: data.user.u_mobile,
								company: data.user.u_company,
								job: data.user.job,
								co_name: data.user.co_name,
								is_freedom: data.user.u_is_freedom,
								u_email: data.user.u_email,
								is_one_edit: data.user.is_one_edit,
								city: [data.user.p_name, data.user.c_name, data.user.a_name],
								xing: data.user.u_name1,
								ming: data.user.u_name2,
								know_path: parseInt(data.user.u_know_path),
								identity: data.user.identity,
							}
							console.log(this.ruleForm);
							this.everyShow = this.ruleForm.is_one_edit == 0 ? true : false
							// this.$store.commit('amendUserId', data.user.id);
						} else {
							this.ruleForm.u_email = data.user.u_email
							this.ruleForm.id = data.user.user_id
							console.log(this.ruleForm)
							this.everyShow = data.user.is_one_edit == 0 ? true : false
						}
						this.$store.commit('amendUserId', data.user.id);
						localStorage.setItem('EveryShow', this.everyShow);
						localStorage.setItem('USERID', data.user.id);
					}
				} catch (error) {
					console.log(error);
				}
			},
			success(a, b) {
				this.ruleForm.avatar = a
			},
			handleChange(value) {
				console.log(value)
				console.log(this.ruleForm.city)
			}
		}
	}
</script>
