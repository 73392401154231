<style lang="less" scoped>
/deep/ .el-dialog__title{
    font-size: 38px;
}
/deep/ .el-dialog__header{
    height: 100px;
    line-height: 100px;
}
/deep/ .el-dialog__body{
    text-align: center;
    font-size: 28px;
}
/deep/ .el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
</style>
<template>
  <div>
    <el-dialog
      :title="title||''"
      :visible.sync="show"
      :width="width||'50%'"
      center
      :before-close="handleCloseBindWarnStandard"
      style="margin:0"
      :show-close='false'
    >
      <slot name="content">{{content}}</slot>
      <span slot="footer" class="dialog-footer">
        <slot name="foot"></slot>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    props:{
        show:{
            type:[Boolean],
            default:false
        },
        title:[String],
        content:[String],
        width:[String],
    },
	data(){
		return{
		}
	},
	watch:{
		show(a,b){
			// console.log(a,b)
		}
	},
    mounted(){
    },
    methods:{
        //关闭弹窗
            handleCloseBindWarnStandard() {
                this.$emit("closeBindWarnStandard",false);
            }
    }
}
</script>