<template>
	<div class="personalCenter">
		<div class="pc">
			<div class="left">
				<div class="headImg">
					<div>
						<img src="../../../assets/info.png" width="40" v-if="image==''" />
						<img :src="image" width="40" v-else />
					</div>
					<div class="headTxt">{{name}}<span
							v-if="type">（{{type==1?$t('personalCenter.personage'):type==2?$t('personalCenter.company'):type==3?$t('personalCenter.organization'):type==4?$t('personalCenter.academy'):""}}）</span>
					</div>
				</div>
				<div class="textList">
					<div v-for="(item,index) in headTxt" :key="index" @click.stop="textClick(index)" class="nr"
						:style="{'color':headTxtIndex==index?'#932224':'#666666'}">
						                                   <!-- ||index==2&&iconShow1 ⬇️-->
						<el-badge is-dot class="item" v-if="index+1==headTxt.length||index==2&&iconShow1&&iconShow">{{item.value}}</el-badge>
						<div v-else>{{item.value}}</div>
					</div>
				</div>
			</div>
			<div class="right" v-if="u_user_type==1">
				<account v-show="headTxtIndex==0"></account>
				<entry v-show="headTxtIndex==1" :is_one_edit="is_one_edit" @everyDalogShow="everyDalogShow"></entry>
				<certificate v-show="headTxtIndex==2"></certificate>
				<recommend v-show="headTxtIndex==3"></recommend>
				<messageNotification v-show="headTxtIndex==4"></messageNotification>
			</div>
			<div class="right" v-else>
				<account v-show="headTxtIndex==0"></account>
				<recommend v-show="headTxtIndex==1"></recommend>
				<messageNotification v-show="headTxtIndex==2"></messageNotification>
			</div>
		</div>
		<dialogs :show="everyShow" :content="u_user_type==1?$t('personalCenter.tishi'):$t('personalCenter.tishi1')" :title="$t('personalCenter.showTitle')">
			<template v-slot:foot>
				<div class="btn">
					<elbutton @btnTap="everyShow=false" :text="$t('personalCenter.showBtn')"></elbutton>
				</div>
			</template>
		</dialogs>
	</div>
</template>

<script>
	import account from './accountInformation'
	import certificate from './certificateDownload'
	import recommend from './recommend'
	import messageNotification from './messageNotification'
	import entry from '../entry.vue'
	import dialogs from '@/components/dialog.vue'
		import elbutton from '@/components/ele/elButton.vue'
	export default {
		components: {
			account,
			certificate,
			recommend,
			messageNotification,
			entry,
			dialogs,
			elbutton
		},
		data() {
			return {
				everyShow: false,
				is_one_edit: false,
				name: "",
				type: null,
				headTxt: [{
						value: this.$t('personalCenter.nav1'),
						show: true,
					},
					{
						value: this.$t('personalCenter.nav2'),
						show: false,
					},
					{
						value: this.$t('personalCenter.nav3'),
						show: false,
					},
					// {
					//   value: '版权登记',
					//   show: false,
					// },
					{
						value: this.$t('personalCenter.nav4'),
						show: false,
					},
					// {
					//   value: '作品邮寄',
					//   show: false,
					// },
					{
						value: this.$t('personalCenter.nav5'),
						show: false,
					},
				],
				headTxtIndex: 0,
				image: "",
				u_user_type:0,
				iconShow:false,
				iconShow1:false
			}
		},
		
		mounted() {
			if (sessionStorage.getItem('headIndex')) {
				this.headTxtIndex = sessionStorage.getItem('headIndex');
				console.log(this.headTxtIndex)
			}
			if(sessionStorage.getItem('iconShow')){
				this.iconShow=false
			}
			this.accountInformationObjs()
			this.messageList()
		},
		methods: {
			async messageList() {
				let _this = this
				try {
					let params = {
						page: 1,
						limit:1000
					}
					let res = await this.$axios.post('notice/getNum', params);
					if (res.code == 1) {
						let data = res.data
							if(data.new_num>0){
								this.iconShow=true
							}else{
								this.iconShow=false
							}
					}
				} catch (error) {
					console.log(error);
				}
			
			},
			everyDalogShow(val) {
				this.everyShow = true
				console.log(this.everyShow)
			},
			async accountInformationObjs() {
				let _this = this
				try {
					let params = {}
					let res = await _this.$axios.post('user/index', params);
					if (res.code == 1) {
						let data = res.data;
						localStorage.setItem('USERTYPE',data.user.u_user_type);
						_this.name = data.user.u_user_type == 1 ? _this.$t('personalCenter.nameType1') : _this.$t(
							'personalCenter.nameType2')
						_this.type = data.user.u_type
						_this.image = data.user.u_head_img ? data.user.u_head_img : ""
						// _this.everyShow = data.user.is_one_edit == 0&&data.user.u_user_type!=2 ? true : false
						_this.everyShow = data.user.is_one_edit !=1 ? true : false
						_this.is_one_edit = data.user.is_one_edit == 0 ? true : false
						_this.u_user_type=data.user.u_user_type
						_this.iconShow1=data.user.is_xiazai002==1?true:false
						if(_this.u_user_type==2){
							this.headTxt= [{
									value: this.$t('personalCenter.nav1'),
									show: true,
								},
								{
									value: this.$t('personalCenter.nav4'),
									show: false,
								},
								{
									value: this.$t('personalCenter.nav5'),
									show: false,
								},
							]
						}
					}	
				} catch (error) {
					console.log(error);
				}

			},
			textClick(txt) {
				this.headTxtIndex = txt;
				console.log("下标"+this.headTxtIndex)
				if(txt==4){
					console.log(111)
					this.iconShow=false
					sessionStorage.setItem('iconShow', this.iconShow);
				}
				if(txt==2){//kabuda
					this.iconShow1=false
				}
				sessionStorage.setItem('headIndex', this.headTxtIndex);
					this.headTxt.forEach((element, index) => {
						element.show = this.headTxtIndex == index ? true : false
					})
				
			
					
					
				
			},
		},
	}
</script>

<style scoped lang="less">
	.btn {
		width: 334px;
		height: 66px;
		margin: 67px auto;
	}
	.textList {
		text-align: center;
		padding: 8px 0;
	}

	.textList .nr {
		font-size: 20px;
		color: #666666;
		cursor: pointer;
		padding: 22px;
	}

	.personalCenter {
		width: 100%;
		background-color: #f5f5f5;
		padding: 53px 49px;
	}

	.pc {
		display: flex;
		width: 1560px;
		margin: 0 auto;
		position: relative;
		// left: -130px;
	}

	.left {
		width: 241px;
		background-color: white;
		margin-right: 20px;
	}

	.right {
		width: 1301px;
		margin: 0 auto;
	}

	.headTxt {
		// width: 1300px;
		font-size: 16px;
		color: #999999;
		// margin: 17px auto;
	}

	.headImg {
		padding: 50px 0;
		height: 176px;
		text-align: center;
		border-bottom: #e3e3e3 solid 1px;
	}
</style>
