<style lang="less" scoped>
	.messageNotification:nth-child(1) {
		border: none;
	}

	.messageNotification {
		padding: 50px;
		display: flex;
		width: 100%;
		background-color: #ffffff;
		border-top: 1px solid #DFDFDF;

		.left {
			img {
				width: 68px;
				height: 68px;
			}
		}

		.right {
			text-align: left;
			padding: 0 15px;

			div {
				font-size: 28px;

				span {
					font-size: 18px;
					color: #999999;
					padding-left: 10px;
				}

			}

			.icon {
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background-color: red;
				margin-left: 10px;
			}

			.text {
				font-size: 22px;
				padding-top: 10px;
			}
		}
	}
	.messageNotificationdu{
		color: #999!important;
	}
</style>
<template>
	<div class="content">
		<div class="tishi" v-if="!tableData||tableData.length==0">
			<el-empty :image-size="200" :description="$t('newText.msg27')"></el-empty>
		</div>
		<div class="messageNotification" :class="[item.is_read==1?'':'messageNotificationdu']" v-for="(item,index) in tableData" :key="index" style="cursor: pointer;" @click="showDetail(item.id)">
			<div class="left">
				<img src="@/assets/xxlogo.png" />
			</div>
			<div class="right">
				<div style="display: flex;align-items: center;">
					{{item.no_title}}
					<span>{{item.create_time}}</span>
					<div class="icon" v-show="item.is_read==1"></div>
				</div>
				<div class="text">{{item.no_detail}}</div>
			</div>
		</div>
		<dialogs :show="show" @closeBindWarnStandard="show=false" :title="obj.no_title"
			:width="'50rem'">
			<template v-slot:content>
				<div class="pass_login">
					{{obj.no_detail}}
				</div>
			</template>
			<template v-slot:foot>
				<div class="btn">
				</div>
			</template>
		</dialogs>
	</div>
</template>

<script>
		import dialogs from '@/components/dialog.vue'
	export default {
		components:{
			dialogs
		},
		data() {
			return {
				tableData: [],
				show:false,
				obj:{}
			}
		},
		mounted() {
			this.messageList()
		},
		methods: {
			async showDetail(id) {
				let _this = this
				try {
					let params = {
						id:id
					}
					let res = await this.$axios.get('notice/detail', {params:params});
					if (res.code == 1) {
						this.obj=res.data
						// this.tableData = data
						this.show=true;
						this.messageList();
					}
				} catch (error) {
					console.log(error);
				}
			
			},
			async messageList() {
				let _this = this
				try {
					let params = {
						page: 1,
						limit: 1000
					}
					let res = await this.$axios.post('notice/index', params);
					if (res.code == 1) {
						let data = res.data.list
						this.tableData = data
					}
				} catch (error) {
					console.log(error);
				}

			},
		}
	}
</script>
