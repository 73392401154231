/**
 * 表单验证
 */

/**
 * 邮箱
 */
export function isEmail (value) {
  return /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)
}
/**
 * 密码
 */
export function isPasswrd(value){
  return /((^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8,16}$)|(^(?=.*\d)(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8,30}$)|(^(?=.*\d)(?=.*[a-z])(?=.*\W)[\da-zA-Z\W]{8,30}$)|(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z\W]{8,30}$))/.test(value)
}
/**
 * 手机号
 */
export function isPhone (value) {
  return /^1[3456789]\d{9}$/.test(value)
}